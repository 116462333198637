import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import SaveIcon from '@material-ui/icons/Save';
import MenuItem from '@material-ui/core/MenuItem';
import { Formik } from 'formik';
import {
  GET_DATA_SUCURSAL_FILTER_ACTIVE  
} from '../../../graphql/Selects';
import { EDIT_COUNTWEB } from 'src/graphql/CountWeb';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import LoadingSpiner from '../../../components/LoadingSpiner';
import Snack from 'src/utils/Common';
import * as Yup from 'yup';


const useStyles = makeStyles(() => ({
  root: {},
  headerStyle: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
  buttonGoBack: {
    marginTop: '1%',
    marginRight: '1%',
  },
}));


const ServiceForm = ({ className, ...rest }) => {
  const classes = useStyles();
  const itemSession = JSON.parse(sessionStorage.getItem('sesTime'));
  const location = useLocation();
  const history = useHistory();
  const [errorForm, setErrorForm] = useState({});
  // const [suc, setSucursal] = useState(location.state.GAST_Sucursal);
  const [setSucursal] = useState(location.state.GAST_Sucursal);
  
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [renderingTime, setRenderingTime] = useState(0);

  const validationSchema = Yup.object().shape({
    MSG_MessageWeb: Yup.number().required('Requiere la cantidad de mensajes.')
  })

  const [initialValues, setInitialValues] = useState({
    MSG_Fecha: '',
    MSG_MessageWeb: '',
    MSG_Sucursal: ''  
  })

  

  const handleChangeSuc = e => {
    if (e.target.value !== '') {
      setSucursal(e.target.value);
      
    }
  };

  const setValuesForm = () => {
    setInitialValues(
      {
        MSG_Fecha: location.state.MSG_CreatedDateTime,
        MSG_MessageWeb: location.state.MSG_MessageWeb,
        MSG_Sucursal: location.state.MSG_Sucursal !== undefined ? location.state.MSG_Sucursal : ''        
      }
    )
    setRenderingTime(1)
  }

  // const goBackButton = () => {
  //   history.push('MensajeWeb')
  // }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };


  const [datasSucursal, setDatasSucursal] = useState([])
  
  const { data: dataSucursal, loading: loading4, error: error4 } = useQuery(GET_DATA_SUCURSAL_FILTER_ACTIVE);
  const [sendEditCount] = useMutation(EDIT_COUNTWEB, {
    onCompleted: data => {
      if (data) {
        setOpen(true)
        setTimeout(() => {
          history.push({
            pathname: '/app/MensajeWeb',
            state: { isRefetch: true },
          });
        }, 1000)

      }
      // <Redirect to='/app/service' />
    }
  })
  //Funcion para enviar datos al backend
  const sendData = async (values) => {
    setLoading(true);
    //Limpio los errores anteriores
    setErrorForm({})
    //Creo el objeto a validar
    let data = {
      MSG_Id: location.state.MSG_Id,
      MSG_CreatedDateTime: values.MSG_Fecha,
      MSG_MessageWeb: values.MSG_MessageWeb,
      MSG_Sucursal: values.MSG_Sucursal,      
      MSG_Modifiedby: itemSession.USR_Id,
      MSG_Active: 1      
    }
    //Valido con promesa
    validationSchema.validate(data)
      //Si es valido se envia el formulario
      .then(async (res) => {
        await sendEditCount({ variables: { data } })
      })
      //Si no se generan los errores. Err solo devuelve 1 error a la vez
      .catch((err) => {
        setLoading(false);
        let object = {}
        object[err.path] = err.errors
        setErrorForm(object)
      })

  }
  useEffect(() => {
    if (dataSucursal && loading4 === false && error4 === undefined) {
      let thing = []
      dataSucursal.GetDataSucursalFilter.map(item => {
        return thing.push({ label: item.SUC_Nombre, value: item.SUC_Id })
      })
      setDatasSucursal(thing)
      setLoading(false)
    }
    // const { data: dataSucursal, loading: loading4, error: error4 } = useQuery(GET_DATA_SUCURSAL_FILTER_ACTIVE);

    if (loading4 === false) {
      if (renderingTime === 0) {
        setValuesForm()
      }
    }
  }, [dataSucursal,
    loading4,
    error4,
    location,
    renderingTime,   
  ])
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values) => {
        await sendData(values);
      }}
    >
      {({
        values,
        touched,
        handleChange,
        handleBlur,
        handleSubmit
      }) => (
        <form {...rest} className={clsx(classes.root, className)} onSubmit={handleSubmit}>
          <Card>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                className={classes.headerStyle}
                xs={12}
              >
                <CardHeader                  
                  title="Mensaje Web"
                />                
              </Grid>
            </Grid>
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={3}
              >
               <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    required
                    label="Fecha de mensaje"
                    type="date"
                    name="MSG_Fecha"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.MSG_Fecha}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                  {errorForm.MSG_Fecha && touched.MSG_Fecha ? (
                    <Typography color="error" variant="subtitle2">
                      {errorForm.MSG_Fecha}
                    </Typography>
                  ) : null}
                </Grid>
                
                
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    required
                    select
                    name="MSG_Sucursal"
                    label="Sucursal"
                    onBlur={handleBlur}
                    value={values.MSG_Sucursal}
                    onChange={handleChangeSuc}
                  >
                    {!!datasSucursal && (datasSucursal.map((option) => (
                      <MenuItem key={`sucursal_${option.value}`} value={option.value}>
                        {option.label}
                      </MenuItem >
                    )))}
                  </TextField>
                  {errorForm.MSG_Sucursal && touched.MSG_Sucursal ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.MSG_Sucursal}
                    </Typography>
                  ) : null}
                
                </Grid>   
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    required
                    label="Mensajes recibidos"
                    name="MSG_MessageWeb"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="number"
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} // Restringe la entrada solo a números
                    value={values.MSG_MessageWeb}
                  />
                  {errorForm.MSG_MessageWeb && touched.MSG_MessageWeb ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.MSG_MessageWeb}
                    </Typography>
                  ) : null}
                </Grid>                       
              </Grid>
            </CardContent>
            <Divider />
            <Box
              display="flex"
              justifyContent="flex-end"
              p={2}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
                startIcon={<SaveIcon />}
              >
                Actualizar
                </Button>
            </Box>

          </Card>
          <Snack open={open} handleClose={handleClose} message="¡Mensajes Actualizados!" />  
          
          <LoadingSpiner estado={loading} />
        </form>
      )}
    </Formik>
  );
};

ServiceForm.propTypes = {
  className: PropTypes.string
};

export default ServiceForm;