import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography,
  makeStyles,
  Tooltip
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import { Formik } from 'formik';
import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import { useHistory } from 'react-router-dom';

import {
  GET_SERV_STATUS,
  GET_SERV_TIPO,
  GET_MTD_PAGO,
  GET_DATA_SUCURSAL,
  GET_BARRIOS,
  GET_Turnos_Origen
} from '../../../graphql/Selects';
import { EDIT_SERVICES } from '../../../graphql/Services';
import { ITEMS_SERVICES } from '../../../graphql/Services';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';

import { useLocation } from 'react-router-dom';
import Snack from '../../../utils/Common';
import AddIcon from '@material-ui/icons/Add';
import { getEstadoId, MapeoSucursales } from '../../../Commons';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Swal from 'sweetalert2';

import CustomShowingItem from '../ItemServices';
import ModalItem from '../ModalItemServices';
import LoadingSpiner from '../../../components/LoadingSpiner';
import { NewFormatDateShort, TurnoHours, NewFormatDateHour, NewFormatDateLong } from '../../../Commons';
import { schemaServicesU } from '../../../shared/validators/Forms';


const useStyles = makeStyles(() => ({
  root: {},
  goBackButton: {
    marginTop: '1%',
    marginRight: '1%'
  },
  displayNone: {
    display: 'none'
  },
  displayBlock: {
    display: 'block'
  },
  headerStyle: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
}));

const ServiceForm = ({ className, ...rest }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [renderingTime, setRenderingTime] = useState(0);
  const [open, setOpen] = useState(false);
  const [countRow, setCountRow] = useState([]);
  const [showingRow, setShowingRow] = useState([]);
  const [loadingState, setLoading] = useState(true);
  const [fecha, setFecha] = useState(NewFormatDateShort(location.state.SERV_Fecha));
  const [dataEdit, setDataEdit] = useState();
  const [sucursal, setSucursal] = useState(location.state.ZON_Region);
  const [barrio, setBarrio] = useState(location.state.ZON_Id);
  // const [Turno, setTurno] = useState(location.state.SERV_TUR_Id);
  // const [opcionesTurno, setOpcionesTurno] = useState([{label: 'turno', value: 1}]);
  const [isDisabledReferencia, setIsDisabledReferencia] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [aNafta, setANafta] = useState(false);
  const [naftaAdicional] = useState(0);
  const [horas, setHoras] = useState(NewFormatDateHour(location.state.SERV_Fecha));
  const [fechaHr, setFechaHr] = useState(NewFormatDateLong(location.state.SERV_Fecha));
  const [fechaFin, setFechaFin] = useState(NewFormatDateLong(location.state.SERV_FechaF));
  const [errorForm, setErrorForm] = useState({});
  const [initialValues, setInitialValues] = useState({
    SERV_Fecha: '',
    SERV_Cliente: '',
    SERV_Telefono: '',
    SERV_Email: '',
    SERV_Direccion: '',
    SERV_Sucursal: '',
    SERV_Barrio: '',
    SERV_Referencia: '',
    SERV_Observacion: '',
    SERV_Estado: '',
    SERV_TUR_Id: '',
    items: '',
    SERV_ANafta: '',
  });
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const goBackButton = () => {
    history.push('services')
  }

  const handleChangeANafta = e => {
    setANafta(!aNafta)
  }

  const handleChangeSucursal = e => {
    if (e.target.value !== '') {
      setSucursal(e.target.value)
      getBarrios({ variables: { data: { Sucursal: e.target.value } } })
      getMtdPago({ variables: { data: { Sucursal: e.target.value } } });
      // loadTurnos({ variables: { data: { fecha: NewFormatDateLong(fecha), Sucursal: e.target.value } } })
    }
  }

  const handleChangeBarrio = (e) => {
    setBarrio(e.target.value)
    if (getLabelBarrio(e.target.value).label === 'Otro') {
      setIsDisabledReferencia(false)
    } else {
      setIsDisabledReferencia(true)
    }
  }

  const handleChangeHour = e => {
    setHoras(e.target.value)
    setFechaHr(fecha + ` ${e.target.value}`)
    setFechaFin(moment(fecha + ` ${e.target.value}`).add(3599, 's').format('YYYY-MM-DD HH:mm:ss'))
    // setFechaFin(moment(fecha + ` ${e.target.value}`).add(7199, 's').format('YYYY-MM-DD HH:mm:ss'))
  }

  const handleChangeFecha = (e) => {
    // loadTurnos({ variables: { data: { fecha: e.target.value } } })
    setFecha(e.target.value);
    if (horas !== '') {
      setFechaHr(e.target.value + ' ' + horas);
      setFechaFin(moment(e.target.value + ` ${horas}`).add(3599, 's').format('YYYY-MM-DD HH:mm:ss'))
      // setFechaFin(moment(e.target.value + ` ${horas}`).add(7199, 's').format('YYYY-MM-DD HH:mm:ss'))
    }
  };

  //Modal para eliminar items
  const editItemServices = (i) => {
    let data = countRow[i]
    data['indice'] = i
    setDataEdit(data);
    setOpenModal(true);
  };
  // console.log(editItemServices)
  //Fin modal para eliminar items
  //Modal para crear items
  const handleModal = () => {
    setDataEdit({
      SERV_Importe: 0,
      SERV_Mtd_pago: '',
      SERV_Servicio: ''
    });
    setOpenModal(!openModal);
  }
  const sendModalData = (data) => {
    let { label: servTipo } = datasServTipo.find(item => item.value === data.SERV_Servicio)
    let { label: mtdopago } = datasMtdPago.find(item => item.value === data.SERV_Mtd_pago)
    if (data.SERV_SID) {
      let i = data.indice
      const newCountRow = countRow.filter((value, theIndex) => { return i !== theIndex });
      const newShowingRow = showingRow.filter((value, theIndex) => { return i !== theIndex });
      let object = {
        SERV_Servicio: data.SERV_Servicio,
        SERV_Importe: Number(data.SERV_Importe),
        SERV_Mtd_pago: data.SERV_Mtd_pago,
        SERV_SID: data.SERV_SID
      }
      let objectShowing = {
        SERV_Servicio: servTipo,
        SERV_Importe: Number(data.SERV_Importe),
        SERV_Mtd_pago: mtdopago,
        SERV_SID: data.SERV_SID
      }
      setCountRow([...newCountRow, object])
      setShowingRow([...newShowingRow, objectShowing])
      handleModal()
    } else {
      let object = {
        SERV_Servicio: data.SERV_Servicio,
        SERV_Importe: Number(data.SERV_Importe),
        SERV_Mtd_pago: data.SERV_Mtd_pago,
      }
      let objectShowing = {
        SERV_Servicio: servTipo,
        SERV_Importe: Number(data.SERV_Importe),
        SERV_Mtd_pago: mtdopago,
      }
      setCountRow([...countRow, object])
      setShowingRow([...showingRow, objectShowing])
      handleModal()
    }
  }
  //Fin modal items crear

  // const deleteItemServices = (i) => {
  //   const newCountRow = countRow.filter((value, theIndex) => { return i !== theIndex });
  //   const newShowingRow = showingRow.filter((value, theIndex) => { return i !== theIndex });
  //   setCountRow(newCountRow);
  //   setShowingRow(newShowingRow);
  // };

  const { loading, error, data: itemsQuery } = useQuery(ITEMS_SERVICES, {
    fetchPolicy: 'no-cache',
    variables: { data: { id: location.state.SERV_Id } }
  })
  // schemaServices
  //Funcion para enviar datos al backend
  const sendData = async (values) => {
    setLoading(true);
    //Limpio los errores anteriores
    setErrorForm({})
    // console.log('Debug',values)
    //Creo el objeto a validar
    let data = {
      CLI_Id: location.state.CLI_Id,
      SERV_Id: location.state.SERV_Id,
      SERV_Fecha: NewFormatDateLong(fechaHr),
      SERV_FechaF: NewFormatDateLong(fechaFin),
      SERV_Sucursal: sucursal,
      SERV_Cliente: values.SERV_Cliente,
      SERV_Telefono: String(values.SERV_Telefono),
      SERV_Email: values.SERV_Email,
      SERV_Direccion: values.SERV_Direccion,
      SERV_Referencia: values.SERV_Referencia,
      SERV_Estado: Number(values.SERV_Estado),
      SERV_TUR_Id: Number(values.SERV_TUR_Id),
      SERV_Barrio: getLabelBarrio(barrio),
      items: countRow,
      countRow: countRow.length,
      SERV_Nafta: aNafta,      
      SERV_ANafta: aNafta === true ? values.SERV_ANafta : 0,
      SERV_Observacion: values.SERV_Observacion,
      SERV_Usuario: JSON.parse(localStorage.getItem('sesTime')).USR_Id,
      FechaInicial: NewFormatDateLong(location.state.SERV_Fecha),
      FechaFinal: NewFormatDateLong(location.state.SERV_FechaF)
    }
    //Valido con promesa
    schemaServicesU.validate(data)
      //Si es valido se envia el formulario
      .then(async (res) => {
        await sendServices({ variables: { data } })
      })
      //Si no se generan los errores. Err solo devuelve 1 error a la vez
      .catch((err) => {
        // console.log(err)
        setLoading(false);
        let object = {}
        object[err.path] = err.errors
        setErrorForm(object)
      })

  }
  const [dataServStatus, setDataServStatus] = useState([])
  const [datasServTipo, setDatasServTipo] = useState([])
  const [datasMtdPago, setDatasMtdPago] = useState([])
  const [datasSucursal, setDatasSucursal] = useState([])
  const [datasBarrios, setDatasBarrios] = useState([])
  const [dataturnosorigen, setDataTurnoOrigen] = useState([])

  const { data: dataStatus, loading: loading1, error: error1 } = useQuery(GET_SERV_STATUS);
  const { data: dataServTipo, loading: loading2, error: error2 } = useQuery(GET_SERV_TIPO);
  const [getMtdPago, { data: dataMtdPago, loading: loading3, error: error3 }] = useLazyQuery(GET_MTD_PAGO, {
    fetchPolicy: "no-cache"
  });
  const { data: dataSucursal, loading: loading4, error: error4 } = useQuery(GET_DATA_SUCURSAL);
  const [getBarrios, { data: dataBarrios, loading: loading5, error: error5 }] = useLazyQuery(GET_BARRIOS, {
    fetchPolicy: "no-cache"
  });
  const { data: dataBarriosLocation, loading: loading6, error: error6 } = useQuery(GET_BARRIOS, {
    fetchPolicy: "no-cache",
    variables: {
      data: {
        Sucursal: sucursal
      }
    }
  });
  const {data: datatorigen, loading: loading7, error:error7 } = useQuery(GET_Turnos_Origen)
  
  const getLabelBarrio = (id) => {
    let response
    datasBarrios.map(item => {
      if (item.value === id) {
        response = { label: item.label, value: item.value }
      }
      return item
    })
    return response
  }
  const [sendServices] = useMutation(EDIT_SERVICES, {
    onCompleted: data => {
      // console.log('Debug', data)
      setLoading(false);
      if (data.editServices.Response) {
        setOpen(true)
        setTimeout(() => {
          history.push({
            pathname: '/app/services',
            state: { isRefetch: true },
          });
        }, 1000)
      } else if (data.editServices.Message) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: data.editServices.Message
        })
      } else if (!data.editServices.Response) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'A ocurrido un error en el sistema contacte al administrador'
        })
      }
    }
  })
  const setValuesForm = () => {
    setInitialValues(
      {
        SERV_Fecha: fecha,
        SERV_Cliente: location.state.CLI_NombreCom,
        SERV_Telefono: location.state.CLI_Telefono,
        SERV_Email: location.state.CLI_Email,
        SERV_Direccion: location.state.CLI_Direccion,
        SERV_Sucursal: sucursal,
        SERV_Barrio: barrio,
        SERV_Referencia: location.state.CLI_Referencia,
        SERV_Observacion: location.state.SERV_Observacion,
        SERV_Estado: getEstadoId(location.state.STATUS_Nombre),
        SERV_TUR_Id: location.state.SERV_TUR_Id,
        // SERV_TUR_Id: Turno,
        items: countRow,
        SERV_ANafta: naftaAdicional,
      }
    )
    getMtdPago({ variables: { data: { Sucursal: sucursal } } });
    setRenderingTime(1)
  }
  useEffect(() => {
    if (dataStatus && loading1 === false && error1 === undefined) {
      let thing = []
      dataStatus.GetDataServStatus.map(item => {
        return thing.push({ label: item.STATUS_Nombre, value: item.STATUS_Id })
      })
      setDataServStatus(thing)
    }
    if (dataServTipo && loading2 === false && error2 === undefined) {
      let thing = []
      dataServTipo.GetDataServTipo.map(item => {
        return thing.push({ label: item.TIPO_Nombre, value: item.TIPO_Id })
      })
      setDatasServTipo(thing)
    }
    if (dataMtdPago && loading3 === false && error3 === undefined) {
      let thing = []
      dataMtdPago.GetDatamtdpago.map(item => {
        return thing.push({ label: item.CTA_Nombre, value: item.SSM_CTA_Id });
      });
      setDatasMtdPago(thing)
    }
    if (itemsQuery && loading === false && error === undefined) {
      let thing = []
      itemsQuery.GetItemServicios.map(item => {
        thing.push({
          SERV_Servicio: item.SERV_SID_Servicio,
          SERV_Importe: item.SERV_SID_Importe,
          SERV_Mtd_pago: item.SERV_SID_MtdPago,
          SERV_SID: item.SERV_SID,
          SERV_ANafta: item.SERV_ANafta,
          SERV_Nafta: item.SERV_Nafta
        })
        return item
      })
      setCountRow(thing)
      let thingShowing = []
      itemsQuery.GetItemServicios.map(item => {
        thingShowing.push({
          SERV_Importe: item.SERV_SID_Importe,
          SERV_Servicio: item.TIPO_Nombre,
          SERV_Mtd_pago: item.MTD_Nombre,
          SERV_SID: item.SERV_SID,
        })
        return item
      })
      setShowingRow(thingShowing)
    }
    if (dataSucursal && loading4 === false && error4 === undefined) {
      let thing = MapeoSucursales(dataSucursal)
      setDatasSucursal(thing)
    }
    if (dataBarrios && loading5 === false && error5 === undefined) {
      let thing = []
      dataBarrios.GetBarrios.map(item => {
        return thing.push({ label: item.ZON_Barrio, value: item.ZON_Id })
      })
      setDatasBarrios(thing)
    }
    if (dataturnosorigen && loading7 === false && error7 === undefined) {
      let thing = []
      datatorigen.GetTurnoOrigenSys.map(item => {
        return thing.push({ label: item.TUR_Nombre, value: item.TUR_Id })
      })
      setDataTurnoOrigen(thing)
    }

    if (location.state.ZON_Region === sucursal) {
      if (dataBarriosLocation && loading6 === false && error6 === undefined) {
        let thing = []
        dataBarriosLocation.GetBarrios.map(item => {
          return thing.push({ label: item.ZON_Barrio, value: item.ZON_Id })
        })
        setDatasBarrios(thing)
      }
    }
    if (loading === false && loading1 === false && loading2 === false && loading3 === false && loading6 === false && loading7 === false) {
      setLoading(false);
      if (renderingTime === 0) {
        setValuesForm()
      }
    }
    // if (location.state.SERV_ANafta !== 0.0000) {
    //   setANafta(true);
    //   setNaftaAdicional(location.state.SERV_ANafta)
    // }
  }, [dataStatus,
    dataServTipo,
    dataMtdPago,
    dataSucursal,
    dataBarrios,
    loading1,
    loading2,
    loading3,
    loading4,
    loading5,
    error1,
    error2,
    error3,
    error4,
    error5,
    loading,
    error,
    itemsQuery,
    dataBarriosLocation,
    loading6,
    loading7,
    error6,
    error7
  ])
  return (
    
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values) => sendData(values)}            
    >
      {({
        values,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        errors
      }) => (
        <form {...rest} className={clsx(classes.root, className)} onSubmit={handleSubmit}>
          <Card>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                // className={classes.headerStyle}
                xs={12}
              >
                <CardHeader
                  // subheader="Editar servicio"
                  title={`Editando Servicio: ${location.state.SERV_Id}`}
                  style={{ backgroundColor: "#138cd8", color: '#FFFFFF' }}
                  action={
                    <Tooltip title="Ir atrás">
                      <IconButton
                        edge="end"
                        color="inherit"
                        // onClick={() => {
                        //   history.push('.');
                        // }}
                        onClick={goBackButton}
                      >
                        <ArrowBackIcon />
                      </IconButton>
                    </Tooltip>}                    
                  
                />

                {/* <Button
                  className={classes.goBackButton}
                  type="button"
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={goBackButton}
                >
                  Atras
                </Button> */}
              </Grid>
            </Grid>
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Fecha de servicio:"
                    type="date"

                    name="SERV_Fecha"
                    onChange={handleChangeFecha}
                    onBlur={handleBlur}
                    value={fecha}
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  {errorForm.SERV_Fecha && touched.SERV_Fecha ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.SERV_Fecha}
                    </Typography>
                  ) : null}
                </Grid >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    select
                    name="SERV_Hrser"
                    label="Hora de servicio:"
                    onBlur={handleBlur}
                    value={horas}
                    onChange={handleChangeHour}
                  >
                    {!!TurnoHours && (TurnoHours.map((option) => (
                      <MenuItem key={`status_${option.value}`} value={option.value}>
                        {option.label}
                      </MenuItem >
                    )))}
                  </TextField>
                  {errorForm.SERV_Hrser && touched.SERV_Hrser ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.SERV_Hrser}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    select
                    name="SERV_TUR_Id"
                    label="Origen del turno:"
                    onBlur={handleBlur}
                    value={values.SERV_TUR_Id}
                    onChange={handleChange}
                  >
                    {!!dataturnosorigen && (dataturnosorigen.map((option) => (
                      <MenuItem key={`TUR_${option.value}`} value={option.value}>
                        {option.label}
                      </MenuItem >
                    )))}
                  </TextField>
                  {errorForm.SERV_TUR_Id && touched.SERV_TUR_Id ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.SERV_TUR_Id}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    select
                    name="SERV_Estado"
                    label="Estado servicio:"
                    onBlur={handleBlur}
                    value={values.SERV_Estado}
                    onChange={handleChange}
                  >
                    {!!dataServStatus && (dataServStatus.map((option) => (
                      <MenuItem key={`status_${option.value}`} value={option.value}>
                        {option.label}
                      </MenuItem >
                    )))}
                  </TextField>
                  {errorForm.SERV_Estado && touched.SERV_Estado ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.SERV_Estado}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Nombre cliente:"
                    name="SERV_Cliente"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.SERV_Cliente}
                  />
                  {errorForm.SERV_Cliente && touched.SERV_Cliente ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.SERV_Cliente}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Numero de teléfono:"
                    name="SERV_Telefono"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="number"
                    value={values.SERV_Telefono}
                  />
                  {errorForm.SERV_Telefono && touched.SERV_Telefono ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.SERV_Telefono}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Correo electronico:"
                    name="SERV_Email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="email"
                    value={values.SERV_Email}
                  />
                  {errorForm.SERV_Email && touched.SERV_Email ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.SERV_Email}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Dirección:"
                    name="SERV_Direccion"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.SERV_Direccion}
                  />
                  {errorForm.SERV_Direccion && touched.SERV_Direccion ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.SERV_Direccion}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    select
                    name="SERV_Sucursal"
                    label="Sucursal:"
                    onBlur={handleBlur}
                    value={sucursal}
                    onChange={handleChangeSucursal}
                  >
                    {!!datasSucursal && (datasSucursal.map((option) => (
                      <MenuItem key={`sucursal_${option.value}`} value={option.value}>
                        {option.label}
                      </MenuItem >
                    )))}
                  </TextField>
                  {errorForm.SERV_Sucursal && touched.SERV_Sucursal ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.SERV_Sucursal}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    select
                    name="SERV_Barrio"
                    label="Barrio / Zona / Ciudad:"
                    onBlur={handleBlur}
                    value={barrio}
                    onChange={handleChangeBarrio}
                  >
                    {!!datasBarrios && (datasBarrios.map((option) => (
                      <MenuItem key={`rol_${option.value}`} value={option.value}>
                        {option.label}
                      </MenuItem >
                    )))}
                  </TextField>
                  {errorForm.SERV_Barrio && touched.SERV_Barrio ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.SERV_Barrio}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    disabled={isDisabledReferencia}
                    label="Barrio / Zona:"
                    name="SERV_Referencia"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.SERV_Referencia}
                  />
                  {errorForm.SERV_Referencia && touched.SERV_Referencia ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.SERV_Referencia}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    className={aNafta === true ? classes.displayBlock : classes.displayNone}
                    label="Nafta adicional:"
                    name="SERV_ANafta"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="number"
                    value={values.SERV_ANafta}
                  />
                  {errorForm.SERV_ANafta && touched.SERV_ANafta ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.SERV_ANafta}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    // className={aNafta === true ? classes.displayBlock : classes.displayNone}
                    label="Observación:"
                    name="SERV_Observacion"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    value={values.SERV_Observacion}
                  />
                  {errorForm.SERV_Observacion && touched.SERV_Observacion ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errorForm.SERV_Observacion}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                  >
                    <Typography
                      variant="subtitle2">
                      Nafta Adicional
                    </Typography>
                    <Switch size="small" name="SERV_Nafta" checked={aNafta} onChange={handleChangeANafta} />
                  </Box>

                </Grid>


                <Grid container spacing={2}>
                <Grid item md={12} xs={12}>
                  {showingRow && showingRow.length > 0 ? (
                    <CustomShowingItem deleteItemServices={e => console.log('Del')} ifDel={false} editItemServices={editItemServices} ifEdit={true} showingRow={showingRow} />
                    ) : null}                  
                    
                </Grid>
                {/* <Grid
                  item
                  md={6}
                  xs={12}
                  >
                {showingRow && showingRow.length > 0 ? (
                    <CustomShowingItem deleteItemServices={deleteItemServices} ifDel={true} editItemServices={console.log('some')} ifEdit={false} showingRow={showingRow} />
                    ) : null}
                    </Grid> */}
                    </Grid>

                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    p={2}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                      startIcon={<AddIcon />}
                      onClick={handleModal}
                    >
                      servicio
                    </Button>
                  </Box>
                </Grid>
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    p={2}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      size="large"
                      className={classes.button}
                      startIcon={<SaveIcon />}
                    >
                      Modificar
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>

          </Card>

          <Snack open={open} handleClose={handleClose} message="¡ Servicio editado !" />

          <LoadingSpiner estado={loadingState} />
          <ModalItem
            estado={openModal}
            handleClose={handleModal}
            dataEdit={dataEdit}
            datasServTipo={datasServTipo}
            datasMtdPago={datasMtdPago}
            sendModalData={sendModalData}
          />
        </form>
      )}

    </Formik>
  );
};

ServiceForm.propTypes = {
  className: PropTypes.string
};

export default ServiceForm;