import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Page from 'src/components/Page';
import { Formik } from 'formik';
import { Visibility } from '@material-ui/icons';
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
  Tooltip
} from '@material-ui/core';
import { GETSERVREALIXDIA } from "../../graphql/Report";
import { useLazyQuery } from '@apollo/react-hooks';
import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';
import Snack from 'src/utils/Common';
import Sales from './DiarioGrafi';

const useStyles = makeStyles(theme => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  head: {
    color: '#FFFFFF',
    backgroundColor: theme.palette.primary.main
  },
  buttonStyle: {
    marginTop: '2%',
    marginRight: '1%'
  }
}));

const DataTable = ({ className, ...rest }) => {
  const classes = useStyles();
  const [dataTable, setDataTable] = useState([]);
  const history = useHistory();

  // Snack
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [snackSeverity, setSnackSeverity] = useState(''); // Añadido para manejar la severidad
  // Snack

  const [findSales] = useLazyQuery(GETSERVREALIXDIA, {
    fetchPolicy: 'no-cache',
    onError: (error) => {
      console.error('Error al ejecutar la consulta:', error);
    },
    onCompleted: data => {
      console.log('FindSales', data.GetServRDia.ServRDia);
      setDataTable(data.GetServRDia.ServRDia);
    }
  });

  const handleCloseSnack = () => {
    setSnackOpen(false);
  };

  // Ejecutar consulta al montar el componente
  useEffect(() => {
    findSales({
      variables: {
        data: {
          FechaINI: new Date().toISOString().substring(0, 10),
          FechaFIN: new Date().toISOString().substring(0, 10),
        }
      }
    });
  }, [findSales]);

  return (
    <Page
      className={classes.root}
      title="Historico de Ventas"
    >
      <Formik
        initialValues={{
          FechaINI: new Date().toISOString().substring(0, 10),
          FechaFIN: new Date().toISOString().substring(0, 10),
        }}
        onSubmit={async (values) => {
          await findSales({
            variables: {
              data: {
                FechaINI: values.FechaINI,
                FechaFIN: values.FechaFIN,
              },
            }
          });
        }}
      >
        {({
          values,
          touched,
          handleSubmit,
          handleChange,
          handleBlur,
          errors
        }) => (
          <Container maxWidth="lg">
            <CardContent>
              <Card>
                <CardHeader
                  title="Historio de Ventas"
                  style={{ backgroundColor: "#138cd8", color: '#FFFFFF' }}
                  action={
                    <Tooltip title="Ir atrás">
                      <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => {
                          history.push('ReportResumenDepositos');
                        }}
                      >
                        <ArrowBackIcon />
                      </IconButton>
                    </Tooltip>
                  }
                />
                <Divider />
                <CardContent>
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item md={2} xs={12}>
                        <TextField
                          fullWidth
                          label="Fecha Inicio"
                          type="date"
                          name="FechaINI"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.FechaINI}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{ style: { textAlign: 'right' } }}
                        />
                        {errors.FechaINI && touched.FechaINI ? (
                          <Typography color="error" variant="subtitle2">
                            {errors.FechaINI}
                          </Typography>
                        ) : null}
                      </Grid>
                      <Grid item md={2} xs={12}>
                        <TextField
                          fullWidth
                          label="Fecha Fin"
                          type="date"
                          name="FechaFIN"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.FechaFIN}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{ style: { textAlign: 'right' } }}
                        />
                        {errors.FechaFIN && touched.FechaFIN ? (
                          <Typography color="error" variant="subtitle2">
                            {errors.FechaFIN}
                          </Typography>
                        ) : null}
                      </Grid>
                      <Grid item md={2} xs={12}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          size="large"
                        >
                          Buscar
                        </Button>
                        <Snack
                          open={snackOpen}
                          handleClose={handleCloseSnack}
                          message={snackMessage}
                          severity={snackSeverity}
                        />
                      </Grid>
                    </Grid>
                  </form>
                  <Divider />
                  <TableContainer component={Paper}>
                    {/* <Table className={classes.table} aria-label="spanning table">
                      <Sales data={dataTable} />
                    </Table> */}
                    <Box mt={3}>
                      <Sales data={dataTable} />
                    </Box>
                  </TableContainer>
                </CardContent>
              </Card>
            </CardContent>
          </Container>
        )}
      </Formik>
    </Page>
  );
};

export default DataTable;
