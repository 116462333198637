// import React from 'react';
// import '../../../node_modules/react-big-calendar/lib/css/react-big-calendar.css';
// import { Calendar, momentLocalizer } from 'react-big-calendar';
// import moment from 'moment';
// import { CalendarColor } from 'src/Commons';
// import "moment/locale/es"

// moment.locale("es");

// const localizer = momentLocalizer(moment) // or globalizeLocalizer

// const MyCalendar = (props) => {
    
//     const classes = CalendarColor();
//     return (
//         <div>
//             <Calendar
//                 localizer={localizer}
//                 // step={60}
//                 messages={{
//                     next: "Siguiente",
//                     previous: "Atrás",
//                     today: "Hoy",
//                     month: "Mes",
//                     week: "Semana",
//                     day: "Dia"
//                 }}
//                 onSelectEvent={event => props.handleClick(event)}
//                 views={["month","week","day"]}
//                 defaultView='week'
//                 // showMultiDayTimes
//                 events={props.events}
//                 startAccessor="start"
//                 endAccessor="end"
                
//                 style={{ height: 800 }}
//                 min={moment("2024-12-19T07:00:00").toDate()}
//                 max={moment("2024-12-19T20:00:00").toDate()}
//                 formats={{
//                     weekdayFormat:date =>{
//                         return moment(date).format("dddd DD/MM")
//                     },
//                     dayHeaderFormat:date =>{
//                         return moment(date).format("dddd DD/MM")
//                     },                            
//                 }}                       
//                 eventPropGetter={
//                     (event) => {
//                         let style = '';
//                         switch (event.STATUS_Nombre) {
//                             case 'Confirmado':
//                                 style = classes.colorTableA
//                                 break;
//                             case 'Cancelado':
//                                 style = classes.colorTableC
//                                 break;
//                             case 'Pendiente':
//                                 style = classes.colorTableP
//                                 break;
//                             case 'Reprogramado':
//                                 style = classes.colorTableR
//                                 break;
//                             default:
//                                 style = ''
//                                 break;
//                         }

//                         return {
//                             className: style,
//                         };
//                     }
//                 }
//             />
//         </div>
//     )
// }

// export default MyCalendar;








import React from 'react';
import '../../../node_modules/react-big-calendar/lib/css/react-big-calendar.css';
import { Calendar,Views, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { CalendarColor } from 'src/Commons';
import { Card, CardContent } from '@material-ui/core';
import "moment/locale/es"

moment.locale("es");


const localizer = momentLocalizer(moment) // or globalizeLocalizer


// const event1 = [
//     {
//         title: 'Este es un evento',
//         start: moment('2024-12-20T12:00:00').toDate(),
//         end: moment('2024-12-20T13:00:00').toDate(),
//     },
//     {
//         title: 'Este es un ',
//         start: moment('2024-12-20T14:00:00').toDate(),
//         end: moment('2024-12-20T15:00:00').toDate(),
//     }
//   ]

const MyCalendar = (props) => {
    // const classes = useStyles();
    const classes = CalendarColor();
    // console.log('Barrio,', JSON.stringify(props))
    // Mapea los datos para preparar los eventos
    // const EventComponent = ({ event }) => {
    //     // Aquí defines cómo quieres que se muestre el evento.
    //     console.log('event', event)
    //     return <span>{event.title}</span>;
    //   };
    // const component = {
    //     event: props => {
    //         console.log(props)
    //         return <div>
    //             {props.title}
    //         </div>
    //     }
    // }

    return (
        <CardContent>
            <Card>
                <div 
                // style={{
                //     height: "95vh",
                //     width: "100vh",
                // }}
                >
                    <Calendar
                        localizer={localizer}                        
                        events={props.events}
                        
                        // events = {event1}
                        views={["month","week","day"]}
                        defaultView={Views.WEEK}
                        // toolbar={true}
                        min={moment("2024-12-19T07:00:00").toDate()}
                        max={moment("2024-12-19T20:00:00").toDate()}
                        formats={{
                            weekdayFormat:date =>{
                                return moment(date).format("dddd DD/MM")
                            },
                            dayHeaderFormat:date =>{
                                return moment(date).format("dddd DD/MM")
                            },                                                        
                        }}                       
                        style={{ height: 700 }}
                        // components={component}
                        // date={moment("2024-12-19T12:00:00").toDate()}
                        step={30}
                        
                        messages={{
                            next: "Siguiente",
                            previous: "Atrás",
                            today: "Hoy",
                            month: "Mes",
                            week: "Semana",
                            day: "Dia",
                        }}

                        
                        // // components={{
                        // //     event: EventComponent, // Sobrescribe cómo se renderiza el evento
                        // //   }}

                        onSelectEvent={event => props.handleClick(event)}
                        eventPropGetter={
                            (event) => {
                                let style = '';
                                switch (event.STATUS_Nombre) {
                                    case 'Confirmado':
                                        style = classes.colorTableA                                        
                                        break;
                                    case 'Cancelado':
                                        style = classes.colorTableC
                                        break;
                                    case 'Pendiente':
                                        style = classes.colorTableP
                                        break;
                                    case 'Reprogramado':
                                        style = classes.colorTableR
                                        break;
                                    default:
                                        style = ''
                                        break;
                                }

                                return {
                                    className: style,
                                };
                            }
                        }
                                               
                    />
                </div>
            </Card>
        </CardContent>
    )
}

export default MyCalendar;