import React, {useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import {
  Box,  
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,  
  colors
} from '@material-ui/core';


const Sales = ({ data }) => {  
  const theme = useTheme();  
  const [datos, setdatos] = useState([]);  
  useEffect(() => {
    // Captura los datos enviados desde el prop
    // console.log('Data recibida en Sales:', data);
    setdatos(data); // Actualiza el estado con los datos recibidos
  }, [data]);
  

  
  const chartData = {
    labels: datos.map(item => item.SUC_Nombre) || [], // Etiquetas para el eje X
    datasets: [
      {
        backgroundColor: colors.indigo[500],
        data: datos.map(item => item.Total) || [], // Valores para las barras
        label: 'This year'
      }
    ]
  };
 
  const options = {
    // animation: true,    
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        barThickness: 12,
        maxBarThickness: 10,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
        ticks: {
          color: theme.palette.text.secondary
        },
        grid: {
          display: false,
          drawBorder: false
        }
      },
      y: {
        ticks: {
          color: theme.palette.text.secondary,
          beginAtZero: true,
          min: 0
        },
        grid: {
          borderDash: [2],
          borderDashOffset: [2],
          color: theme.palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: theme.palette.divider
        }
      }
    },
    plugins: {
      tooltip: {
        backgroundColor: theme.palette.background.default,
        bodyColor: theme.palette.text.secondary,
        borderColor: theme.palette.divider,
        borderWidth: 1,
        enabled: true,
        footerColor: theme.palette.text.secondary,
        intersect: false,
        mode: 'index',
        titleColor: theme.palette.text.primary
      }
    }
  };

  return (
    <Card
   
    >
      <CardHeader        
        title="Gráfico de Ventas" style={{ backgroundColor: "#138cd4", color: '#FFFFFF' }}
      />
      <Divider />
      <CardContent>
        <Box
          height={400}
          position="relative"
        >
          <Bar
            data={chartData}
            options={options}
          />
        </Box>
      </CardContent>
      <Divider />      
    </Card>
  );
};

Sales.propTypes = {
  className: PropTypes.string  
};

export default Sales;
