import React, { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TablePagination,
  makeStyles,
  Button
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useQuery } from '@apollo/react-hooks';

import { GET_ALLTSERVICIOS } from "../../../graphql/AddServicios";
import { useHistory, useLocation } from 'react-router-dom';
import { Visibility,Edit } from '@material-ui/icons';
import {RowsxList} from '../../../Commons'
import AddIcon from '@material-ui/icons/Add';


const columns = [
  { field: 'somme', headerName: '', width: 130 },
  { field: 'som1', headerName: '', width: 130 },
  { field: 'Descripcion', headerName: 'Descripción', width: 130 },

];

const useStyles = makeStyles(theme => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  head: {
    color: '#FFFFFF',
    backgroundColor: theme.palette.primary.main,
  }
}));

const Sucursal = ({ className, ...rest }) => {
  const classes = useStyles();
  const [dataTable, setDataTable] = useState([])
  const history = useHistory();
  const { loading: loading_Services, error: error_Services, data: servicesData, refetch } = useQuery(GET_ALLTSERVICIOS)
  const location = useLocation();

  const handleClick = (tipo,data) => {
    if(tipo === 'edit'){
      history.push({
        pathname: 'editAddServicios',
        state: data
      })
    } else {
      history.push({
        pathname: 'verAddServicios',
        state: data
      })
    }
  }
  
  useEffect(() => {
    let thing = []
    if (servicesData && loading_Services === false && error_Services === undefined) {
      // console.log(servicesData)
      servicesData.GetAddServicio.map(item => {
        item['id'] = item.TIPO_Id;
        item['Descripcion'] = item.TIPO_Nombre;   
        return thing.push(item)
      })
      setDataTable(thing);
    }
    if(location){
      if(location.state != null){
        if(location.state.isRefetch)
        refetch()
      }
    }
  }, [servicesData, error_Services, loading_Services, location, refetch])


  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(RowsxList);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const GoToNew = () => {
    history.push('/app/NewAddServicios')}
  return (
    <Card
      // className={clsx(classes.root, className)}
      // {...rest}
    >
      <CardHeader 
      title="Servicios" 
      style={{ backgroundColor: "#138cd8", color: '#FFFFFF' }}
            action={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* <RouterLink className={classes.buttonStyle} to='/app/newGasto'> */}
                  <Button                                        
                    color="inherit"
                    size="large"             
                    onClick={ GoToNew}       
                    startIcon={<AddIcon />}
                  >
                    Nuevo
                  </Button>
                {/* </RouterLink> */}

                {/* <Tooltip title="Ir atrás">
                  <IconButton
                    edge="end"
                    color="inherit"
                    // onClick={() => {
                    //   history.push('.');
                    // }}
                    onClick={goBackButton}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                </Tooltip> */}
              </div>
            }
      />
      <Divider />
      <PerfectScrollbar>
        <TableContainer className={classes.container}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((rowsName) => (
                  <TableCell className={classes.head} key={rowsName.field}>
                    {rowsName.headerName}
                  </TableCell>
                ))}
                  <TableCell className={classes.head} >
                  Ver
                  </TableCell>
                  <TableCell className={classes.head} >
                  Modificar
                  </TableCell>                
              </TableRow>
            </TableHead>
            <TableBody>
              {dataTable.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((order,i) => {
                return (
                  <TableRow style={ i % 2? { background : "#f4f6f8" }:{ background : "white" }} hover role="checkbox" tabIndex={-1} key={order.id}>
                    {columns.map((column) => {
                      const value = order[column.field];
                      return (
                        <TableCell key={column.field}>
                          {column.format && typeof value === 'number' ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                    <TableCell> 
                      <Visibility onClick={() => handleClick('ver',order)} />
                    </TableCell>
                    <TableCell>
                      <Edit onClick={() => handleClick('edit',order)} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 50]}
          component="div"
          count={dataTable.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </PerfectScrollbar>
    </Card>
  );
}

export default Sucursal;