import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Page from 'src/components/Page';
import { Formik } from 'formik';

import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';

import ExportIcon from '@material-ui/icons/SaveAlt';

import Snack, {getFirstDayOfMonth,getLastDayOfMonth} from 'src/utils/Common';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
  Tooltip
  
} from '@material-ui/core';
import { GETALLCLIENTMONTH } from "../../graphql/Report";
import { GET_DATA_SUCURSAL } from "../../graphql/Selects";
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import {  
  MapeoSucursales
} from '../../Commons'
import MenuItem from '@material-ui/core/MenuItem';
import { generatePDF } from 'src/utils/GeneralPDF';


const columns = [
  { field: 'CLI_NombreCom', headerName: 'Nombre', width: 130 },
  { field: 'CLI_Telefono', headerName: 'Telefono', width: 130 },
  { field: 'CLI_Email', headerName: 'Email', width: 130 }, 
];

const useStyles = makeStyles(theme => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  head: {
    color: '#FFFFFF',
    backgroundColor: theme.palette.primary.main
  },
  buttonStyle: {
    marginTop: '2%',
    marginRight: '1%'
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover, // Puedes cambiar esto al color que prefieras
    },
  },
}));


const DataTable = ({ className, ...rest }) => {
  const classes = useStyles();
  const [dataTable, setDataTable] = useState([])
  const [datasSucursal, setDatasSucursal] = useState();

  const history = useHistory();

  //Snack
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [snackSeverity, setSnackSeverity] = useState(''); // Añadido para manejar la severidad
  //Snack
  const { data: dataSucursal, loading, error } = useQuery(GET_DATA_SUCURSAL);
  const [findSales] = useLazyQuery(GETALLCLIENTMONTH, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      // console.log(data.GetAllClientMonth)
      setDataTable(data.GetAllClientMonth)
      // Mostrar mensaje de éxito tras la búsqueda
      // setSnackMessage('Datos encontrados.');
      // setSnackSeverity('success');
      // setSnackOpen(true);
    },
    onError: () => {
      // Mostrar mensaje de error si la búsqueda falla
      setSnackMessage('Debe seleccionar una sucursal primero.');
      setSnackSeverity('error');
      setSnackOpen(true);
    }
  })

  useEffect(() => {
    if (dataSucursal && loading === false && error === undefined) {
      let thing = MapeoSucursales(dataSucursal)
      setDatasSucursal(thing)
    }
  }, [dataTable, dataSucursal, loading, error])

  const handleCloseSnack = () => {
    setSnackOpen(false);
  };

  const handleExport = () => {
    if (dataTable.AllClientMonth && dataTable.AllClientMonth.length > 0) {
      const reportTitle = `Clientes de ${dataTable.AllClientMonth[0].SUC_Nombre || 'Desconocido'}`;
      generatePDF(reportTitle, columns, dataTable.AllClientMonth);

      // Mostrar mensaje de éxito
      setSnackMessage('Reporte exportado con éxito.');
      setSnackSeverity('success'); // Establecer severidad a éxito
      setSnackOpen(true);
    } else {
      // Mostrar mensaje de error
      setSnackSeverity('error'); // Establecer severidad a error
      setSnackMessage('No hay datos disponibles para exportar.');
      setSnackOpen(true);
    }
  };

  return (
    <Page
      className={classes.root}
      title="Listado de Clientes"
    >
      <Formik
        initialValues={{
          FechaIni: getFirstDayOfMonth(),
          FechaFin: getLastDayOfMonth(),
          SERV_Sucursal: ''
        }}
        onSubmit={async (values) => {
          if (!values.SERV_Sucursal) {
            setSnackMessage('Debe seleccionar una sucursal primero.');
            setSnackSeverity('error');
            setSnackOpen(true);
            return;
          }

          await findSales({
            variables: {
              data: {
                FechaIni: values.FechaIni,
                FechaFin: values.FechaFin,
                SERV_Sucursal: values.SERV_Sucursal,
              },
            }
          })

        }}
      >
        {({
          values,
          touched,
          handleSubmit,
          handleChange,
          handleBlur,
          errors
        }) => (
          <Container maxWidth="lg">            
            <CardContent>
              <Card>
                <CardHeader
                  title="Reporte de Clientes"               
                  style={{ backgroundColor: "#138cd8", color: '#FFFFFF' }}
                  action={
                    <Tooltip title="Ir atrás">
                      <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => {
                          // Lógica para navegar hacia atrás                          
                          // Puedes usar router para redirigir, por ejemplo:
                          history.push('.');

                        }}
                      >
                        <ArrowBackIcon />
                      </IconButton>
                    </Tooltip>
                  }
                />
                <Divider />
                <CardContent>
                  <form onSubmit={handleSubmit}>                 
                    <Grid
                      container
                      spacing={2}
                      direction="row" // Organizar en columna
                    >
                      <Grid
                        item
                        md={2}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Fecha Inicio"
                          type="date"
                          name="FechaIni"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.FechaIni}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        {errors.FechaIni && touched.FechaIni ? (
                          <Typography
                            color="error"
                            variant="subtitle2"
                          >
                            {errors.FechaIni}
                          </Typography>
                        ) : null}
                      </Grid>
                      <Grid
                        item
                        md={2}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Fecha Fin"
                          type="date"
                          name="FechaFin"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.FechaFin}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        {errors.FechaFin && touched.FechaFin ? (
                          <Typography
                            color="error"
                            variant="subtitle2"
                          >
                            {errors.FechaFin}
                          </Typography>
                        ) : null}
                      </Grid>
                      <Grid
                        item
                        md={3}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          select
                          name="SERV_Sucursal"
                          label="Sucursal"
                          onBlur={handleBlur}
                          value={values.SERV_Sucursal}
                          onChange={handleChange}
                        >
                          {datasSucursal && datasSucursal.length>0?
                          (
                            datasSucursal.map((option) =>(
                              <MenuItem key={`sucursal_${option.value}`} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))
                          ):(
                            <MenuItem disabled>No hay sucursales disponibles</MenuItem>
                          )}
                          
                        </TextField>
                        {errors.SERV_Sucursal && touched.SERV_Sucursal ? (
                          <Typography
                            color="error"
                            variant="subtitle2"
                          >
                            {errors.SERV_Sucursal}
                          </Typography>
                        ) : null}
                      </Grid>
                      <Grid
                        item
                        md={2}
                        xs={12}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          size="large"
                        >
                          Buscar
                        </Button>
                      </Grid>
                      <Grid
                        item
                        md={2}
                        xs={12}
                      >
                        <Tooltip title="Exportar Reporte" arrow>
                          {/* Este <span> es para evitar que el Tooltip no se muestre si el botón está deshabilitado */}
                          <span>
                            <Button
                              type="button"
                              variant="contained"
                              color="primary"
                              size="large"
                              endIcon={<ExportIcon />}
                              // onClick={() => generatePDF('Clientes de '+dataTable.AllClientMonth.SUC_Nombre,columns, dataTable.AllClientMonth)}
                              onClick={handleExport}
                            >
                              Exportar
                            </Button>
                          </span>
                        </Tooltip>
                        {/* Agrega el componente Snack */}
                        <Snack
                          open={snackOpen}
                          handleClose={handleCloseSnack}
                          message={snackMessage}
                          severity={snackSeverity} // Pasar severidad al Snack
                        />
                      </Grid>
                    </Grid>

                  </form>
                  <Divider />
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="spanning table">
                      <TableHead>
                        <TableRow>
                          {columns.map((rowsName) => (
                            <TableCell align="center" className={classes.head} key={rowsName.field}>
                              {rowsName.headerName}
                            </TableCell>

                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {!!dataTable.AllClientMonth && dataTable.AllClientMonth.map((row, i) => (
                          <TableRow key={i} className={classes.tableRow}>
                            <TableCell align='center'>{row.CLI_NombreCom}</TableCell>
                            <TableCell align='center'>{row.CLI_Telefono}</TableCell>
                            <TableCell align='center'>{row.CLI_Email}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </CardContent>
          </Container>

        )}
        
      </Formik>
      
    </Page>
  );
}


export default DataTable;
