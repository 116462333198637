import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography,
  makeStyles,
  Tooltip
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';

import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


import { Formik } from 'formik';
import * as Yup from 'yup';


import { NEW_ADDSERVICIOS } from '../../../../graphql/AddServicios';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import Snack from 'src/utils/Common';



const useStyles = makeStyles(() => ({
  root: {}
}));

const ServiceForm = ({ className, ...rest }) => {
  const classes = useStyles();
  const history = useHistory();
  const itemSession = JSON.parse(localStorage.getItem('sesTime'));
  const [open, setOpen] = useState(false);


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  // const [datasSucursal, setDatasSucursal] = useState([])
  // const [datasMtdPago, setDatasMtdPago] = useState([])

  // const { data: dataMtdPago, loading: loading3, error: error3 } = useQuery(GET_MTD_PAGO);
  // const { data: dataSucursal, loading: loading4, error: error4 } = useQuery(GET_DATA_SUCURSAL);
  const [sendMtdPago] = useMutation(NEW_ADDSERVICIOS, {
    onCompleted: data => {
      // console.log(data);
      setOpen(true)
      setTimeout(() => {
        history.push({
          pathname: '/app/AddServicios',
          state: { isRefetch: true },
        });
      }, 1000)
    }
  })

  useEffect(() => {
    // if (dataMtdPago && loading3 === false && error3 === undefined) {
    //   let thing = []
    //   dataMtdPago.GetDatamtdpago.map(item => {
    //     return thing.push({ label: item.mtd_nombre, value: item.mtd_id })
    //   })
    //   setDatasMtdPago(thing)
    // }
  }, [
    // dataMtdPago,
    // loading3,
    // error3
  ])
  const goBackButton = () => {
    // history.push('services')
    history.push({
      pathname: 'AddServicios',
      state: { isRefetch: true }
    })
  }
  return (
    <Formik
      initialValues={{
        TIPO_Nombre: '',

      }}
      validationSchema={Yup.object().shape({
        TIPO_Nombre: Yup.string().max(25).required('Campo requerido!'),
      })}
      onSubmit={async (values, actions) => {
        await sendMtdPago({
          variables: {
            data: {
              TIPO_Nombre: values.TIPO_Nombre,
              TIPO_ByModify: itemSession.USR_Id,
            },
          }
        })
        actions.resetForm({
          values: {
            TIPO_Nombre: '',

          },
          // you can also set the other form states here
        });
      }}
    >
      {({
        values,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        errors
      }) => (
        <form {...rest} className={clsx(classes.root, className)} onSubmit={handleSubmit}>
          <Card>
            <CardHeader
              title="Genera un nuevo servicio"
              style={{ backgroundColor: "#138cd8", color: '#FFFFFF' }}
              action={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {/* <RouterLink className={classes.buttonStyle} to='/app/newGasto'> */}
                  {/* <Button
                    color="inherit"
                    size="large"
                    onClick={GoToNew}
                    startIcon={<AddIcon />}
                  >
                    Nuevo
                  </Button> */}
                  {/* </RouterLink> */}

                  <Tooltip title="Ir atrás">
                    <IconButton
                      edge="end"
                      color="inherit"
                      // onClick={() => {
                      //   history.push('.');
                      // }}
                      onClick={goBackButton}
                    >
                      <ArrowBackIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              }
            />
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Servicio:"
                    name="TIPO_Nombre"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.TIPO_Nombre}
                  />
                  {errors.TIPO_Nombre && touched.TIPO_Nombre ? (
                    <Typography
                      color="error"
                      variant="subtitle2">
                      {errors.TIPO_Nombre}
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box
              display="flex"
              justifyContent="flex-end"
              p={2}
            >
              <Button
                color="primary"
                variant="contained"
                type="submit"
                startIcon={<SaveIcon />}
              >
                Guardar
              </Button>
            </Box>
          </Card>

          <Snack open={open} handleClose={handleClose} message="¡Servicio creado!" />

        </form>
      )}

    </Formik>
  );
};

ServiceForm.propTypes = {
  className: PropTypes.string
};

export default ServiceForm;