import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Page from 'src/components/Page';
import { Formik } from 'formik';
import { Visibility } from '@material-ui/icons';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
  Tooltip
} from '@material-ui/core';
import { GETHISTORICODEPOSITOS } from "../../graphql/Report";
import { GET_DATA_SUCURSAL } from "../../graphql/Selects";
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import {
  NewFormatDateShort, 
  MapeoSucursales,  
  DecimalesInReport
} from '../../Commons'
import MenuItem from '@material-ui/core/MenuItem';
// import data from '../customer/CustomerListView/data';
import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';
import Snack from 'src/utils/Common';

const columns = [
  // { field: 'DP_Id', headerName: 'ID', width: 130 },
  { field: 'DP_Fecha', headerName: 'Fecha', width: 130 },  
  { field: 'DP_Referencia', headerName: 'Concepto', width: 130 },
  { field: 'DP_Monto', headerName: 'Monto', width: 130 },
  { field: 'DP_Monto1', headerName: 'Ver', width: 130 },  
];

const useStyles = makeStyles(theme => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  head: {
    color: '#FFFFFF',
    backgroundColor: theme.palette.primary.main
  },
  buttonStyle: {
    marginTop: '2%',
    marginRight: '1%'
  }
}));


const DataTable = ({ className, ...rest }) => {
  const classes = useStyles();
  const [dataTable, setDataTable] = useState([])
  const [datasSucursal, setDatasSucursal] = useState();

  const history = useHistory();
    //Snack
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [snackSeverity, setSnackSeverity] = useState(''); // Añadido para manejar la severidad
    //Snack
  
  const handleClick = (tipo, data) => {
    if (tipo === 'edit') {
      history.push({
        pathname: 'editDeposito',
        state: data
      })
    } else {
      history.push({
        pathname: 'verDeposito',
        state: data
      })
    }
  }

  const { data: dataSucursal, loading, error } = useQuery(GET_DATA_SUCURSAL);
  const [findSales] = useLazyQuery(GETHISTORICODEPOSITOS, {
    fetchPolicy: 'no-cache',
    onError: (error) => {
      console.error('Error al ejecutar la consulta:', error);
    },
    onCompleted: data => {
      console.log('FindSales',data.GetHisDepo)
      setDataTable(data.GetHisDepo)
    }
  })
  

  useEffect(() => {
    if (dataSucursal && loading === false && error === undefined) {
      let thing = MapeoSucursales(dataSucursal)
      setDatasSucursal(thing)
    }
  }, [dataTable, dataSucursal, loading, error])

  const handleCloseSnack = () => {
    setSnackOpen(false);
  };
  
  // Función para obtener el último día del mes actual
  const getLastDayOfMonth = () => {
    const today = new Date();
    const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    return lastDay.toISOString().split('T')[0];
  };

  // Función para obtener el primer día del mes actual
  const getFirstDayOfMonth = () => {
    const today = new Date();
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    return firstDay.toISOString().split('T')[0];
  };

  return (
    <Page
      className={classes.root}
      title="Historico de Depositos"
    >
      <Formik
        initialValues={{
          FechaIni: getFirstDayOfMonth(),
          FechaFin: getLastDayOfMonth(),
          SERV_Sucursal: ''
        }}
      
        onSubmit={async (values) => {
          if (!values.SERV_Sucursal) {
            setSnackMessage('Debe seleccionar una sucursal primero.');
            setSnackSeverity('error');
            setSnackOpen(true);
            return;
          }

          await findSales({
            variables: {
              data: {
                FechaIni: values.FechaIni,
                FechaFin: values.FechaFin,
                SERV_Sucursal: values.SERV_Sucursal,
              },
            }
          })

        }}
      >
        {({
          values,
          touched,
          handleSubmit,
          handleChange,
          handleBlur,
          errors
        }) => (
          <Container maxWidth="lg">
            <CardContent>
              <Card>
                <CardHeader
                  title="Historio de Depositos"
                  style={{ backgroundColor: "#138cd8", color: '#FFFFFF' }}
                  action={
                    <Tooltip title="Ir atrás">
                      <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => {
                          // Lógica para navegar hacia atrás                          
                          // Puedes usar router para redirigir, por ejemplo:
                          history.push('ReportResumenDepositos');

                        }}
                      >
                        <ArrowBackIcon />
                      </IconButton>
                    </Tooltip>
                  }
                />
                <Divider />
                <CardContent>
                  <form onSubmit={handleSubmit}>
                    <Grid
                      container
                      spacing={2}
                    >
                      <Grid
                        item
                        md={2}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Fecha Inicio"
                          type="date"
                          name="FechaIni"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.FechaIni}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{ style: { textAlign: 'right' } }}
                        />
                        {errors.FechaIni && touched.FechaIni ? (
                          <Typography
                            color="error"
                            variant="subtitle2">
                            {errors.FechaIni}
                          </Typography>
                        ) : null}
                      </Grid >
                      <Grid
                        item
                        md={2}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Fecha Fin"
                          type="date"
                          name="FechaFin"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.FechaFin}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{ style: { textAlign: 'right' } }}
                        />
                        {errors.FechaFin && touched.FechaFin ? (
                          <Typography
                            color="error"
                            variant="subtitle2">
                            {errors.FechaFin}
                          </Typography>
                        ) : null}
                      </Grid >
                      <Grid
                        item
                        md={3}
                        xs={12}
                        style={{ textAlign: 'right' }}
                      >
                        <TextField
                          fullWidth
                          select
                          name="SERV_Sucursal"
                          label="Sucursal"
                          onBlur={handleBlur}
                          value={values.SERV_Sucursal}
                          onChange={handleChange}
                          inputProps={{ style: { textAlign: 'right' } }}
                        >
                          {!!datasSucursal && (datasSucursal.map((option) => (
                            <MenuItem key={`sucursal_${option.value}`} value={option.value}>
                              {option.label}
                            </MenuItem >
                          )))}
                        </TextField>
                        {errors.SERV_Sucursal && touched.SERV_Sucursal ? (
                          <Typography
                            color="error"
                            variant="subtitle2">
                            {errors.SERV_Sucursal}
                          </Typography>
                        ) : null}
                      </Grid >
                      <Grid
                        item
                        md={2}
                        xs={12}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          size="large"
                        >
                          Buscar
                        </Button>
                        <Snack
                          open={snackOpen}
                          handleClose={handleCloseSnack}
                          message={snackMessage}
                          severity={snackSeverity} // Pasar severidad al Snack
                        />
                      </Grid>
                    </Grid>
                  </form>
                  <Divider />
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="spanning table">
                      <TableHead>
                        <TableRow>
                          {columns.map((rowsName) => (
                            <TableCell align="center" className={classes.head} key={rowsName.field}>
                              {rowsName.headerName}
                            </TableCell>

                          ))}                          
                          
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {!!dataTable.HisDepo && (dataTable.HisDepo.map((row, i) => (
                          <TableRow key={dataTable.HisDepo.length + i + '-servicios'}>
                            {/* <TableCell align="center">{`S${row.DP_Id}`}</TableCell> */}
                            <TableCell align="center">{NewFormatDateShort(row.DP_Fecha)}</TableCell>
                            <TableCell align="center">{row.DP_Referencia}</TableCell>
                            <TableCell align="center">{`$${row.DP_Monto.toFixed(DecimalesInReport)}`}</TableCell>
                            {/* <TableCell align="center"></TableCell> */}
                            {/* <TableCell align="center">{row.MTD_Nombre}</TableCell> */}
                         <TableCell align='center'>
                            <Visibility onClick={() => handleClick('ver', row)} />
                        </TableCell>
                          </TableRow>
                        )))}                       
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </CardContent>
            <Grid
              container
              spacing={3}
              direction="row"
              justify="flex-end"
            >
              <Grid
                item
                md={6}
                xs={12}
              >

                
              </Grid>

            </Grid>


          </Container>

        )}
      </Formik>
    </Page>
  );
}


export default DataTable;
