import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Page from 'src/components/Page';
import { Formik } from 'formik';


import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';

import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
  Tooltip
} from '@material-ui/core';
import { GET_ALL_TurAddUser1 } from "../../graphql/Report";
// import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { useLazyQuery } from '@apollo/react-hooks';
// import { Tooltip } from 'chart.js';
import { RangoSell } from 'src/utils/CalReportService';
import { DecimalesInReport } from 'src/Commons';


const columns = [
  { field: 'SUC_Nombre', headerName: 'Sucursal', width: 130 },
  { field: 'USR_Id', headerName: 'Usuario', width: 130 },
  { field: 'Turnos', headerName: 'Turnos', width: 130 },
  { field: 'Importe', headerName: 'Ventas', width: 130 },
  { field: 'Procentaje', headerName: 'Porcentaje', width: 130 },
  { field: 'Comision', headerName: 'Comisión', width: 130 },
];

const useStyles = makeStyles(theme => ({
  root: { }, // Agregar espacio para el pie de página},
  actions: {
    justifyContent: 'flex-end'
  },
  head: {
    color: '#FFFFFF',
    backgroundColor: theme.palette.primary.main
  },
  buttonStyle: {
    marginTop: '2%',
    marginRight: '1%'
  }

}));


const DataTable = ({ className, ...rest }) => {
  const classes = useStyles();
  const [dataTable, setDataTable] = useState([]) 
  const history = useHistory();

  // const [AllTur, { loading, error, data }] = useLazyQuery(GET_ALL_TurAddUser1, {
  //   fetchPolicy: 'no-cache',
  //   onError: (error) => {
  //     console.error('Error al ejecutar la consulta:', error);
  //   },
  //   onCompleted: (data) => {
  //     setDataTable(data.GetAllTurAddUserD);
  //     // console.log('DEBUG', JSON.stringify(data.GetAllTurAddUser));
  //   },
  // })
  const [AllTur] = useLazyQuery(GET_ALL_TurAddUser1, {
    fetchPolicy: 'no-cache',
    onError: (error) => {
      console.error('Error al ejecutar la consulta:', error);
    },
    onCompleted: (data) => {
      setDataTable(data.GetAllTurAddUserD);
      // console.log('DEBUG', JSON.stringify(data.GetAllTurAddUser));
    },
  })

  useEffect(() => {
    // Cuando el componente se monta, cargar los datos
    AllTur({
      variables: {
        data: {
          FechaIni: getCurrentDay(),
          FechaFin: getCurrentDay()              
        },
      }
    });
  }, [AllTur]);

//   // Función para obtener el último día del mes actual
//   const getLastDayOfMonth = () => {
//     const today = new Date();
//     const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
//     return lastDay.toISOString().split('T')[0];
//   };

//     // Función para obtener el primer día del mes actual
//   const getFirstDayOfMonth = () => {
//   const today = new Date();
//   const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
//   return firstDay.toISOString().split('T')[0];
// };
const getCurrentDay = () => {
  const today = new Date();
  return today.toISOString().split('T')[0];
};

  

  return (
    <Page
    className={classes.root}
    title="Informe turnos"
    >
      <Formik
        initialValues={{
          FechaIni: getCurrentDay(),
          FechaFin: getCurrentDay()          
        }}
        onSubmit={async (values) => {
          await AllTur({
            variables: {
              data: {
                FechaIni: values.FechaIni,
                FechaFin: values.FechaFin                
              },
            }
          })          

        }}
      >
        {({
          values,
          touched,
          handleSubmit,
          handleChange,
          handleBlur,
          errors
          
        }) => (
          <Container maxWidth="lg">
            
            <CardContent>
              <Card>
                <CardHeader
                  title="Informe de turnos agendados Por Dia"
                  style={{ backgroundColor: "#138cd8", color: '#FFFFFF' }}
                  action={
                    <Tooltip title="Ir atrás">
                      <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => {
                          // Lógica para navegar hacia atrás                          
                          // Puedes usar router para redirigir, por ejemplo:
                          history.push('.');

                        }}
                      >
                        <ArrowBackIcon />
                      </IconButton>
                    </Tooltip>
                  }
                />
                <Divider />
                <CardContent>
                  <form onSubmit={handleSubmit}>
                    <Grid
                      container
                      spacing={3}
                    >
                      <Grid
                        item
                        md={3}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Fecha Inicio"
                          type="date"
                          name="FechaIni"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.FechaIni}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        {errors.FechaIni && touched.FechaIni ? (
                          <Typography
                            color="error"
                            variant="subtitle2">
                            {errors.FechaIni}
                          </Typography>
                        ) : null}
                      </Grid >
                      <Grid
                        item
                        md={3}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          label="Fecha Fin"
                          type="date"
                          name="FechaFin"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.FechaFin}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        {errors.FechaFin && touched.FechaFin ? (
                          <Typography
                            color="error"
                            variant="subtitle2">
                            {errors.FechaFin}
                          </Typography>
                        ) : null}
                      </Grid >
                      
                      <Grid
                        item
                        md={3}
                        xs={12}
                      >
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          size="large"
                        >
                          Buscar
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                  <Divider />
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="spanning table">
                      <TableHead>
                        <TableRow>
                          {columns.map((rowsName) => (
                            <TableCell align="center" className={classes.head} key={rowsName.field}>
                              {rowsName.headerName}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>

                        {!!dataTable.AllTurAddUserD && (dataTable.AllTurAddUserD.map((row, index) => (
                          <TableRow key={index}>
                              <TableCell align='center'>{row.SUC_Nombre}</TableCell>
                              <TableCell align='center'>{row.SERV_Usuario}</TableCell>                              
                              <TableCell align='center'>{row.Turnos}</TableCell>
                              <TableCell align='center'>${row.Importe}</TableCell>
                              <TableCell align='center'>{row.SSC_RateComission}%</TableCell>
                              <TableCell align='center'>${row.Comision}</TableCell>
                              {/* <TableCell align='center'>${row.ImporteNeto}</TableCell> */}
                              {/* <TableCell align='center'>{RangoSell(row.Importe.toFixed(DecimalesInReport)).XUsed}%</TableCell> */}
                              {/* <TableCell align='center'>${RangoSell(row.Importe.toFixed(DecimalesInReport)).RSell}</TableCell> */}
                              
                            </TableRow>
                          ))
                        ) }
                   

                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </CardContent>
            <Grid
              container
              spacing={3}
              direction="row"
              justify="flex-end"
            >
              <Grid
                item
                md={6}
                xs={12}
              >


              </Grid>

            </Grid>


         
    
          </Container>

        )}
      </Formik>
    </Page>
  );
}


export default DataTable;
