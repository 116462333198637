import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Doughnut, Bar, Line } from 'react-chartjs-2'; // Puedes importar más tipos de gráficos si es necesario
import { Box, Card, CardContent, Divider, colors, useTheme } from '@material-ui/core';

const ChartComponent = ({ data, type, label }) => {
  const theme = useTheme();
  const [chartData, setChartData] = useState({});

  useEffect(() => {
    setChartData({
      labels: data?.map(item => item.label) || [],
      datasets: [
        {
          label: label || [],
          backgroundColor: generateColors(data.length),
          data: data.map(item => item.value),
          borderWidth: 2,
          borderColor: colors.common.white,
          hoverBorderColor: colors.common.white
        }
      ]
    });
  }, [data]);

  const generateColors = (numColors) => {
    const colorPalette = [
      colors.indigo?.[500],
      colors.red?.[600],
      colors.orange?.[600],
      colors.green?.[500],
      colors.purple?.[500],
      colors.yellow?.[600],
      colors.blue?.[500],
      colors.pink?.[500],
      colors.teal?.[500],
      colors.brown?.[500],
      colors.lime?.[600],
      colors.cyan?.[500],
      colors.deepPurple?.[500],
      colors.lightGreen?.[500],
      colors.deepOrange?.[500],
      colors.amber?.[500],
      colors.grey?.[600],
      colors.lightBlue?.[500],
      colors.blueGrey?.[500],
      colors.red?.[700],
      colors.orange?.[700],
      colors.green?.[600],
      colors.purple?.[700],
      colors.yellow?.[700],
      colors.blue?.[600],
      colors.pink?.[600],
      colors.teal?.[600],
      colors.brown?.[600],
      colors.lime?.[700],
      colors.cyan?.[600],
      colors.deepPurple?.[600],
      colors.lightGreen?.[600],
      colors.deepOrange?.[600],
      colors.blueGrey?.[500],
      colors.darkGreen?.[500], // Algunos de estos colores podrían no estar disponibles
      colors.darkOrange?.[500], // Dependiendo de tu configuración de Material-UI
    ].filter(Boolean);

    return Array.from({ length: numColors }, (_, i) => colorPalette[i % colorPalette.length]);
  };

  const options = {
    animation: false,
    cutoutPercentage: type === 'doughnut' ? 60 : 0,
    layout: { padding: 0 },
    legend: {
      display: true,
      position: 'left', // Posición de la leyenda (puedes ajustar según tus necesidades)
      labels: {
        boxWidth: 20,
        padding: 15,
        fontColor: '#000000', // Color de la fuente
      }
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      intersect: false,
      mode: 'point',
      callbacks: {
        label: function (tooltipItem, data) {
          const label = data.labels[tooltipItem.index] || '';
          const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] || '';
          return [`${label}`, `Valor: ${value}`];
        }
      }
    }
  };

  const renderChart = () => {
    switch (type) {
      case 'bar':
        return <Bar data={chartData} options={options} />;
      case 'line':
        return <Line data={chartData} options={options} />;
      case 'doughnut':
      default:
        return <Doughnut data={chartData} options={options} />;
    }
  };

  return (
    <Card>
      {/* <CardHeader title={title} style={{ backgroundColor: "#138cd4", color: '#FFFFFF' }} /> */}
      <Divider />
      <CardContent>
        <Box height={400} position="relative">
          {renderChart()}
        </Box>
      </CardContent>
    </Card>
  );
};

ChartComponent.propTypes = {
  // title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired
  })).isRequired,
  type: PropTypes.oneOf(['doughnut', 'bar', 'line']).isRequired,
};

export default ChartComponent;
