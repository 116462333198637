import React, { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TablePagination,
  makeStyles,
  Grid,  
  Button
  
} from '@material-ui/core';
import {NewFormatDateShort,RowsxList} from '../../Commons';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory, useLocation } from 'react-router-dom';
// import { Link as RouterLink } from 'react-router-dom';
// import clsx from 'clsx';
import { Visibility,Edit } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
// import { IconButton } from '@material-ui/core';
// import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_ALLINVENTARIO } from 'src/graphql/Inventario';
import Toolbar from 'src/shared/componentes/Toolbar';


const columns = [
  { field: 'id', headerName: 'ID', width: 130 },
  { field: 'Fecha', headerName: 'Fecha', width: 130 },
  { field: 'SUC_Nombre', headerName: 'Sucursal', width: 130 },
  { field: 'AF_Nombre', headerName: 'Equipo', width: 130 },
  { field: 'AF_Asignada', headerName: 'Asignada', width: 130 },

];

const useStyles = makeStyles(theme => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  head: {
    color: '#FFFFFF',
    backgroundColor: theme.palette.primary.main
  },
  buttonStyle: {
    marginTop: '2%',
    marginRight: '1%'
  }
}));

const DataTable = (props) => {
  const classes = useStyles();
  const [dataTable, setDataTable] = useState([])
  const history = useHistory();
  const location = useLocation();

  const handleClick = (tipo,data) => {
    if(tipo === 'edit'){
      history.push({
        pathname: 'editInventario',
        state: data
      })
    } else {
      history.push({
        pathname: 'verInventario',
        state: data
      })
    }
  }

  const [sucursalSearch, SetSucursalSearch] = useState('');
  const handlerChangeSuc = (e) => {
    SetSucursalSearch(e.target.value)
  }
  const onClickFunction = () => {
    // console.log('Buscando datos con sucursal:', sucursalSearch);
    if (sucursalSearch !== '') {
      searchData({ variables: { data: { sucursal: sucursalSearch } } })
    }
  }

  const [searchData, { loading: loading_Services, error: error_Services, data: servicesData }] = useLazyQuery(GET_ALLINVENTARIO, {
    fetchPolicy: "no-cache",
  })

  
  useEffect(() => {
    let thing = []
    if (servicesData && loading_Services === false && error_Services === undefined) {
      servicesData.GetInventario.map(item => {
        item['id'] = item.AF_Id;
        item['Fecha'] = NewFormatDateShort(item.AF_FechaCompra);
        
        return thing.push(item)
      })
      setDataTable(thing);
    }
  }, [servicesData, error_Services, loading_Services, location])
  
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(RowsxList);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  // const goBackButton = () => {
  //   // history.push('services')
  //   history.push({
  //     pathname: 'gastos',
  //     state: { isRefetch: true }
  //   })  
  // }

  const GoToNew = () => {
    history.push('/app/Newinventario')}

  return (
    <Card
      // className={clsx(classes.root, className)}
      // {...rest}
    >
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          // md={6}
          xs={12}
        >
          {/* <Box display="flex" justifyContent="flex-start"> */}
            <CardHeader title="Inventario" 
            style={{ backgroundColor: "#138cd8", color: '#FFFFFF' }}
            action={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* <RouterLink className={classes.buttonStyle} to='/app/newGasto'> */}
                  <Button                                        
                    color="inherit"
                    size="large"             
                    onClick={ GoToNew}       
                    startIcon={<AddIcon />}
                  >
                    Nuevo
                  </Button>
                {/* </RouterLink> */}

                {/* <Tooltip title="Ir atrás">
                  <IconButton
                    edge="end"
                    color="inherit"
                    // onClick={() => {
                    //   history.push('.');
                    // }}
                    onClick={goBackButton}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                </Tooltip> */}
              </div>
            }
            />
             <Toolbar
          onClickFunction={onClickFunction}
          valueSuc={sucursalSearch}
          handlerChangeSuc={handlerChangeSuc}
          className={classes.spaceButtom}
        />

          {/* </Box> */}

        </Grid>

        <Grid
          item
          md={6}
          xs={12}
        >

          {/* <Box
            display="flex"
            justifyContent="flex-end"
          >
            <RouterLink className={classes.buttonStyle} to='/app/NewInventario'>
              <Button
                color="primary"
                variant="contained"
                className={classes.button}
                startIcon={<AddIcon />}
              >
                Nuevo
              </Button>
            </RouterLink>
          </Box> */}
        </Grid>
      </Grid>
      <Divider />
      <PerfectScrollbar>
        <TableContainer className={classes.container}>
          <Table >
            <TableHead>
              <TableRow>
                {columns.map((rowsName) => (
                  <TableCell className={classes.head} key={rowsName.field}>
                    {rowsName.headerName}
                  </TableCell>
                ))}
                <TableCell className={classes.head} >
                   Ver
                  </TableCell>
                  <TableCell className={classes.head} >
                   Modificar
                  </TableCell>
                {/* <TableCell sortDirection="desc">
                  <Tooltip
                    enterDelay={300}
                    title="Sort"
                  >
                    <TableSortLabel
                      active
                      direction="desc"
                    >
                      Date
                    </TableSortLabel>
                  </Tooltip>
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataTable.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((order,i) => {
                return (
                  <TableRow style={ i % 2? { background : "#f4f6f8" }:{ background : "white" }} hover role="checkbox" tabIndex={-1} key={order.id}>
                    {columns.map((column) => {
                      const value = order[column.field];
                      return (
                        <TableCell key={column.field}>
                          {column.format && typeof value === 'number' ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                    <TableCell>
                      <Visibility onClick={() => handleClick('ver',order)} />
                    </TableCell>
                    <TableCell>
                      <Edit onClick={() => handleClick('edit',order)} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 50]}
          component="div"
          count={dataTable.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </PerfectScrollbar>
    </Card>
  );
}

export default DataTable;