import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Page from 'src/components/Page';
import { Formik } from 'formik';
import ChartComponent from '../../utils/ChartComponent';  // Asegúrate de importar tu componente

import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
  Tooltip,
  MenuItem
} from '@material-ui/core';
import { GETTOPSERVICE } from "../../graphql/Report";
import { GET_DATA_SUCURSAL } from "../../graphql/Selects";
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { MapeoSucursales } from 'src/Commons';

import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';
import Snack from 'src/utils/Common';

// import Sucursal from '../config/sucursal';

const useStyles = makeStyles(theme => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  head: {
    color: '#FFFFFF',
    backgroundColor: theme.palette.primary.main
  },
  buttonStyle: {
    marginTop: '2%',
    marginRight: '1%'
  }
}));

const DataTable = ({ className, ...rest }) => {
  const classes = useStyles();
  const [dataTable, setDataTable] = useState([]);
  const [datasSucursal, setDatasSucursal] = useState();

  const { data: dataSucursal, loading, error } = useQuery(GET_DATA_SUCURSAL,{
    fetchPolicy: "no-cache"
  });
  const history = useHistory();
  //Snack
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [snackSeverity, setSnackSeverity] = useState(''); // Añadido para manejar la severidad
  //Snack

  const [findSales] = useLazyQuery(GETTOPSERVICE, {
    fetchPolicy: 'no-cache',
    onError: (error) => {
      console.error('Error al ejecutar la consulta:', error);
    },
    onCompleted: (data) => {
      // console.log('Debug', data.GetTopService)
      setDataTable(data.GetTopService);
    },
  });

 

  useEffect(() => {
    if (dataSucursal && !loading && !error) {
      const mappedData = MapeoSucursales(dataSucursal);
      setDatasSucursal(mappedData);
    }
  }, [dataSucursal, loading, error]);

  const handleCloseSnack = () => {
    setSnackOpen(false);
  };

  const getLastDayOfMonth = () => {
    const today = new Date();
    const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    return lastDay.toISOString().split('T')[0];
  };

  const getFirstDayOfMonth = () => {
    const today = new Date();
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    return firstDay.toISOString().split('T')[0];
  };

  return (
    <Page className={classes.root} title="Informe de rendicion">
      <Formik
        initialValues={{
          FechaIni: getFirstDayOfMonth(),
          FechaFin: getLastDayOfMonth(),
          SERV_Sucursal: ''  
        }}
        onSubmit={async (values) => {
          if (!values.SERV_Sucursal) {
            setSnackMessage('Debe seleccionar una sucursal primero.');
            setSnackSeverity('error');
            setSnackOpen(true);
            return;
          }

          await findSales({
            variables: {
              data: {
                FechaIni: values.FechaIni,
                FechaFin: values.FechaFin,
                SERV_Sucursal: values.SERV_Sucursal
              },
            }
          });
        }}
      >
        {({
          values,
          touched,
          handleSubmit,
          handleChange,
          handleBlur,
          errors
        }) => (
          <Container maxWidth="lg">
            <CardContent>
              <Card>
                <CardHeader
                  title="Top de servicios por zona"
                  style={{ backgroundColor: "#138cd8", color: '#FFFFFF' }}
                  action={
                    <Tooltip title="Ir atrás">
                      <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => {
                          history.push('.');
                        }}
                      >
                        <ArrowBackIcon />
                      </IconButton>
                    </Tooltip>
                  }
                />
                <Divider />
                <CardContent>
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item md={2} xs={12}>
                        <TextField
                          fullWidth
                          label="Fecha Inicio"
                          type="date"
                          name="FechaIni"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.FechaIni}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        {errors.FechaIni && touched.FechaIni ? (
                          <Typography color="error" variant="subtitle2">
                            {errors.FechaIni}
                          </Typography>
                        ) : null}
                      </Grid>
                      <Grid item md={2} xs={12}>
                        <TextField
                          fullWidth
                          label="Fecha Fin"
                          type="date"
                          name="FechaFin"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.FechaFin}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                        {errors.FechaFin && touched.FechaFin ? (
                          <Typography color="error" variant="subtitle2">
                            {errors.FechaFin}
                          </Typography>
                        ) : null}
                      </Grid>
                      <Grid item md={3} xs={12} style={{ textAlign: 'right' }}>
                        <TextField
                          fullWidth
                          select
                          name="SERV_Sucursal"
                          label="Sucursal"
                          onBlur={handleBlur}
                          value={values.SERV_Sucursal}
                          onChange={handleChange}
                          inputProps={{ style: { textAlign: 'right' } }}
                        >
                           {datasSucursal && datasSucursal.length>0?
                          (
                            datasSucursal.map((option) =>(
                              <MenuItem key={`sucursal_${option.value}`} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))
                          ):(
                            <MenuItem disabled>No hay sucursales disponibles</MenuItem>
                          )}
                          
                        </TextField>
                        {errors.SERV_Sucursal && touched.SERV_Sucursal ? (
                          <Typography color="error" variant="subtitle2">
                            {errors.SERV_Sucursal}
                          </Typography>
                        ) : null}
                      </Grid>
                      <Grid item md={3} xs={12}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          size="large"
                        >
                          Buscar
                        </Button>
                        <Snack
                          open={snackOpen}
                          handleClose={handleCloseSnack}
                          message={snackMessage}
                          severity={snackSeverity} // Pasar severidad al Snack
                        />
                      </Grid>
                    </Grid>
                  </form>
                  <Divider />
                  <TableContainer component={Paper}>

                  </TableContainer>
                </CardContent>
              </Card>
            </CardContent>
            <CardContent>

              <TableContainer component={Paper}>
                <ChartComponent                  
                   data={Array.isArray(dataTable.TopService) ? dataTable.TopService.map(item => ({
                     label: item.TIPO_Nombre,
                     value: item.Cantidad
                   })) : []}
                   type="doughnut"
                />
              </TableContainer>
            </CardContent>
          </Container>
        )}
      </Formik>
    </Page>
  );
};

export default DataTable;
