import React, { useState } from 'react';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import DepositosTable from './DepositosTable';
// import Toolbar from '../../shared/componentes/Toolbar';
import { GET_ALLDEPOSITOS } from "../../graphql/Deposito";
import { useLazyQuery } from '@apollo/react-hooks';
import useSessionTimeout from 'src/hook/AuthSesion';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  spaceButtom: {
    paddingBottom: '1%'  
  }
}));

const Depositos = () => {
  const classes = useStyles();
  const [sucursalSearch, SetSucursalSearch] = useState('')
  // const handlerChangeSuc = (e) => {
  //   SetSucursalSearch(e.target.value)
  // }
  // const onClickFunction = () => {
  //   if (sucursalSearch !== '') {
  //     searchData({ variables: { data: { sucursal: sucursalSearch } } })
  //   }
  // }
  // const [searchData, { loading: loading_Services, error: error_Services, data: servicesData }] = useLazyQuery(GET_ALLDEPOSITOS, {
  //   fetchPolicy: "no-cache",
  // })
  const [{ loading: loading_Services, error: error_Services, data: servicesData }] = useLazyQuery(GET_ALLDEPOSITOS, {
    fetchPolicy: "no-cache",
  })
  useSessionTimeout();
  return (
    <Page
      className={classes.root}
      title="Depositos"
    >
      <Container maxWidth="lg">
        {/* <Toolbar
          onClickFunction={onClickFunction}
          valueSuc={sucursalSearch}
          handlerChangeSuc={handlerChangeSuc}
          className={classes.spaceButtom} /> */}
        <Grid container spacing={3}>
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            <DepositosTable
              loading_Services={loading_Services}
              error_Services={error_Services}
              servicesData={servicesData}
              sucursalSearch={sucursalSearch} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}

export default Depositos;