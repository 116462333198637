import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  makeStyles,
  Grid,
  TextField,  
  CardContent,  
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import MenuItem from '@material-ui/core/MenuItem';
import { GET_DATA_SUCURSAL } from '../../graphql/Selects'
import { useQuery } from '@apollo/react-hooks';
import { MapeoSucursales } from '../../Commons';
import { useLocation } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  headerStyle: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
}));

const Toolbar = (props) => {
  const classes = useStyles();
  const [sucursalesSelect, setSucursalesSelect] = useState();
  const [selectedSucursal, setSelectedSucursal] = useState('');

  const location = useLocation();

  const handleChangeHere = e => {
    props.handlerChangeSuc(e)
  }
  const { data: dataSucursal, loading: loading4, error: error4, refetch } = useQuery(GET_DATA_SUCURSAL, {
    fetchPolicy: "no-cache"
  });
  useEffect(() => {
    if (dataSucursal && loading4 === false && error4 === undefined) {
      let thing = MapeoSucursales(dataSucursal)
      setSucursalesSelect(thing);
      if (Array.isArray(thing) && thing.length > 0) {
        setSelectedSucursal(thing[0].value); // Set the first option as selected
        props.handlerChangeSuc({ target: { value: thing[0].value } }); // Trigger onChange event        
      }
    }

    if (location) {
      if (location.state != null) {
        if (location.state.isRefetch)
          refetch()

      }
    }
  }, [dataSucursal, loading4, error4])
  // console.log(sucUser)
  return (
    <CardContent >
      {/* <div
        // className={clsx(classes.root, className)}
        // {...rest}
        > */}
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        {/* <CardHeader title={"aa"} style={{ backgroundColor: "#138cd8", color: '#FFFFFF' }}
        /> */}

        <Grid
          item
          md={3}
          xs={12}
        >
          <TextField
            fullWidth
            select
            name="SERV_Sucursal"
            // label="Sucursal"
            value={props.valueSuc}
            onChange={handleChangeHere}

          >
            {!!sucursalesSelect && (sucursalesSelect.map((option) => (
              <MenuItem key={`sucursal_${option.value}`} value={option.value}>
                {option.label}
              </MenuItem >
            )))}
          </TextField>
        </Grid>

        <Grid>
          <Button
            color="primary"
            variant="contained"
            onClick={props.onClickFunction}
          >
            Buscar
          </Button>

        </Grid>
        <Grid
          item
          md={6}
          xs={12}
        >
          <Box
            // display="flex"
            justifyContent="flex-end"
          >

            <RouterLink to='/app/newService'>
              <Button
                color="primary"
                variant="contained"
                className={classes.button}
                startIcon={<AddIcon />}
              >
                Nuevo
              </Button>
            </RouterLink>
          </Box>
        </Grid>
      </Grid>
      {/* </div> */}
    </CardContent>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
