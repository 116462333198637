import React, { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TablePagination,
  makeStyles,
  Grid,
  Button
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory, useLocation } from 'react-router-dom';

import { Visibility, Edit } from '@material-ui/icons';
import { DecimalesInReport, NewFormatDateShort, RowsxList } from '../../Commons';
import AddIcon from '@material-ui/icons/Add';


import Toolbar from '../../shared/componentes/Toolbar';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_ALL_GASTOS } from 'src/graphql/Gasto';

const columns = [
  { field: 'id', headerName: 'ID', width: 130 },
  { field: 'Fecha', headerName: 'Fecha', width: 130 },
  { field: 'SUC_Nombre', headerName: 'Sucursal', width: 130 },
  { field: 'MTD_Nombre', headerName: 'Método de pago', width: 130 },
  { field: 'GAST_Descripcion', headerName: 'Descripción', width: 130 },
  { field: 'Importe', headerName: 'Importe', width: 130 },
  { field: 'GAST_NumFact', headerName: 'N° Factura', width: 130 },
];


const useStyles = makeStyles(theme => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  },
  head: {
    color: '#FFFFFF',
    backgroundColor: theme.palette.primary.main
  },
  buttonStyle: {
    marginRight: '1%'
  },
  headerStyle: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between'
  }
}));




const DataTable = (props) => {
  const classes = useStyles();
  const [dataTable, setDataTable] = useState([])
  const history = useHistory();
  const location = useLocation();
  // const { loading: loading_Services, error: error_Services, data: servicesData, refetch } = useQuery(GET_ALL_GASTOS)

  const handleClick = (tipo, data) => {
    if (tipo === 'edit') {
      history.push({
        pathname: 'EditGasto',
        state: data,
      })
    } else {
      history.push({
        pathname: 'verGasto',
        state: data,
      })
    }
  }

  const [sucursalSearch, SetSucursalSearch] = useState('');
  const handlerChangeSuc = (e) => {
    SetSucursalSearch(e.target.value)
  }
  const onClickFunction = () => {
    // console.log('Buscando datos con sucursal:', sucursalSearch);
    if (sucursalSearch !== '') {
      searchData({ variables: { data: { sucursal: sucursalSearch } } })
    }
  }

  const [searchData, { loading: loading_Services, error: error_Services, data: servicesData }] = useLazyQuery(GET_ALL_GASTOS, {
    fetchPolicy: "no-cache",
  })

  useEffect(() => {
    let thing = [];
    if (servicesData && loading_Services === false && error_Services === undefined) {
      servicesData.GetGastos.map((item) => {
        item['id'] = item.GAST_Id;
        item['Fecha'] = NewFormatDateShort(item.GAST_Fecha);
        item['Importe'] = "$" + (item.GAST_Importe).toFixed(DecimalesInReport);
        thing.push(item)
      })
      setDataTable(thing);
    }
  }, [servicesData, error_Services, loading_Services, location])

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(RowsxList);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // const goBackButton = () => {
  //   // history.push('services')
  //   history.push({
  //     pathname: 'gastos',
  //     state: { isRefetch: true }
  //   })  
  // }

  const GoToNew = () => {
    history.push('/app/newGasto')}

  return (
    <Card

    >
      <Grid
        container
        spacing={1}
      >
        <Grid
          item
          // className={classes.headerStyle}
          xs={12}
        >
          <CardHeader
            title="Gastos"
            style={{ backgroundColor: "#138cd8", color: '#FFFFFF' }}
            action={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* <RouterLink className={classes.buttonStyle} to='/app/newGasto'> */}
                  <Button                                        
                    color="inherit"
                    size="large"             
                    onClick={ GoToNew}       
                    startIcon={<AddIcon />}
                  >
                    Nuevo
                  </Button>
                {/* </RouterLink> */}

                {/* <Tooltip title="Ir atrás">
                  <IconButton
                    edge="end"
                    color="inherit"
                    // onClick={() => {
                    //   history.push('.');
                    // }}
                    onClick={goBackButton}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                </Tooltip> */}
              </div>
            }
          />
        </Grid>
        

        <Toolbar
          onClickFunction={onClickFunction}
          valueSuc={sucursalSearch}
          handlerChangeSuc={handlerChangeSuc}
          className={classes.spaceButtom}
        />
      </Grid>
      <Divider />
      <PerfectScrollbar>
        <TableContainer className={classes.container}>
          <Table >
            <TableHead>
              <TableRow>
                {columns.map((rowsName) => (
                  <TableCell className={classes.head} key={rowsName.field}>
                    {rowsName.headerName}
                  </TableCell>

                ))}
                <TableCell className={classes.head} >
                  Ver
                </TableCell>
                <TableCell className={classes.head} >
                  Modificar
                </TableCell>
                {/* <TableCell sortDirection="desc">
                  <Tooltip
                    enterDelay={300}
                    title="Sort"
                  >
                    <TableSortLabel
                      active
                      direction="desc"
                    >
                      Date
                    </TableSortLabel>
                  </Tooltip>
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataTable.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((order, i) => {
                return (
                  <TableRow style={i % 2 ? { background: "#f4f6f8" } : { background: "white" }} hover role="checkbox" tabIndex={-1} key={order.id}>
                    {columns.map((column) => {
                      const value = order[column.field];
                      return (
                        <TableCell key={column.field}>
                          {column.format && typeof value === 'number' ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                    <TableCell>
                      <Visibility onClick={() => handleClick('ver', order)} />
                    </TableCell>
                    <TableCell>
                      <Edit onClick={() => handleClick('edit', order)} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 50]}
          component="div"
          count={dataTable.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </PerfectScrollbar>
    </Card>
  );
}

export default DataTable;